import $ from "jquery";
import 'slick-slider';
import gsap from "gsap";

$(document).ready(function(){

  // nav
  $('.hamburger').on('click', function(){
    if ($(this).hasClass('is-active')) {
      $(this).removeClass('is-active');
      gsap.to('.main-nav', 0.5, {height: 0, ease: "power4.inOut"});
      gsap.to('.sidebar-contact', 0.5, {height: 0, opacity: 0, ease: "power4.inOut"});
    } else {
      $(this).addClass('is-active');
      gsap.set('.main-nav', {height: 'auto'});
      gsap.from('.main-nav', 0.5, {height: 0, ease: "power4.inOut"});
      gsap.set('.sidebar-contact', {height: 'auto', opacity: 1});
      gsap.from('.sidebar-contact', 0.5, {delay: 0.2, opacity: 0, height: 0, ease: "power4.inOut"});
    }
  });

  //homepage slider
  $('.main-slider').slick({
    fade: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: 'linear'
  });


  // set start height
  var calStartHeight = $('.home-calendar').innerHeight();

  // homepage calendar slide down
  $('.cal-expand').on('click', function(){

    if ($(this).hasClass('open')) {
      $(this).removeClass('open');
      gsap.to('.home-calendar', 0.5, {height: calStartHeight, ease: 'power4.out' });
      gsap.set('.cal-expand', {rotate: 0 });
    } else {
      $(this).addClass('open');
      gsap.set('.home-calendar', {height: 'auto'});
      gsap.from('.home-calendar', 0.5, {height: calStartHeight, ease: 'power4.out' });
      gsap.from('.slide-down .flex-container', 0.5, {opacity: 0, stagger: 0.1 });
      gsap.set('.cal-expand', {rotate: 180 });
    }
  });


  // calendar page

  if ($('body').hasClass('calendar')) {
    // tooltips on events
    $('.event').on('hover',function(){
      var qtip = $(this).find('.qtip');
      if (!qtip.hasClass('open')) {
        gsap.to('.qtip', 0.1, {opacity: 0, onComplete: function(){
          gsap.set('.qtip', {display: 'none'});
          gsap.set(qtip, {display: 'block'});
          gsap.to(qtip, 0.1, {opacity: 1});
          qtip.addClass('open');
        }})
      } else {
        qtip.removeClass('open');
        gsap.to(qtip, 0.1, {opacity: 0, onComplete: function(){
          gsap.set(qtip, {display: 'none'});
        }});
      }
    });


    $('body').on('click', function(e){
      if (!$('.event').has(e.target).length > 0){
        gsap.to('.qtip', 0.1, {opacity: 0, onComplete: function(){
          gsap.set('.qtip', {display: 'none'});
          $('.qtip').removeClass('open');
        }});
      }
    })
  }


  // sermon page

  // if ($('body').hasClass('sermons')) {
  //
  //   $('.sermon-main.has-dropdown').on('click', function(){
  //     var el = $(this);
  //     var dropdown = $(this).next('.sermon-dropdown');
  //
  //     if (dropdown.hasClass('open')) {
  //       gsap.to(dropdown, 0.2, {height: 0, onComplete: function(){
  //         el.removeClass('open');
  //         dropdown.removeClass('open');
  //       }});
  //     } else {
  //       dropdown.addClass('open');
  //       el.addClass('open');
  //       gsap.set(dropdown, {height: 'auto'});
  //       gsap.from(dropdown, 0.2, {height: 0});
  //     }
  //
  //   })
  //
  // }



});
